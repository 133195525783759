import Cosmo_logo from '@/images/cosmopolitan_logo.svg?svgr'
import MensHealth_logo from '@/images/mens_health_logo.svg?svgr'
import TheIPaper_logo from '@/images/the_i_paper_logo.png'
import Independent_logo from '@/images/the_independent_logo.svg?svgr'
import TheTimes_logo from '@/images/the_times_logo.svg?svgr'
import * as UI from '@/ui'

export const AsFeaturedIn = () => {
  return (
    <UI.Block className="w-full">
      <UI.Heading as="h3" size="xxs" color="black" className="text-center">
        As featured in
      </UI.Heading>

      <div className="flex no-scrollbar h-32 items-center justify-around gap-x-5 overflow-x-scroll md:h-44">
        {features.map((feature) => (
          <UI.Link
            key={feature.name}
            type="text"
            to={feature.link}
            className="flex h-24 w-40 items-center justify-center rounded-lg bg-selphWhite-100 shadow-[0px_0px_16px_0px_rgba(0,0,0,0.1)] hover:shadow-[0px_0px_16px_6px_rgba(0,0,0,0.1)] max-md:p-6 md:h-32 md:w-56"
          >
            {feature.logo}
          </UI.Link>
        ))}
      </div>
    </UI.Block>
  )
}

export default AsFeaturedIn

const features = [
  {
    name: 'Cosmopolitan Magazine',
    logo: <Cosmo_logo width={128} />,
    link: 'https://www.cosmopolitan.com/uk/body/diet-nutrition/a46866572/leaky-gut-syndrome/',
  },
  {
    name: "Men's Health",
    logo: <MensHealth_logo width={128} />,
    link: 'https://www.menshealth.com/uk/health/a63615081/healthiest-city-in-the-uk/',
  },
  {
    name: 'The I Paper',
    logo: <UI.Image src={TheIPaper_logo} alt="The I Paper logo" width={128} />,
    link: 'https://inews.co.uk/inews-lifestyle/signs-vitamin-deficient-sore-tongue-ulcers-headaches-3469685',
  },
  {
    name: 'The Times',
    logo: <TheTimes_logo width={128} />,
    link: 'https://www.thetimes.co.uk/article/colon-cancer-experience-symptoms-first-person-mpzwzddr9',
  },
  {
    name: 'The Independent',
    logo: <Independent_logo width={128} className="py-0.5" />,
    link: 'https://www.independent.co.uk/life-style/women-mental-health-uk-europe-sadness-b2542377.html',
  },
]
